import Orders from './charts/Orders';
import Repeaters from './charts/Repeaters';
import Sales from './charts/Sales';
import RepeaterOrders from './charts/RepeaterOrders';
import ChannelOrders from './charts/ChannelOrders';
import ChannelOrdersDetail from './charts/ChannelOrdersDetail';
import WeeklyRepeaters from './charts/WeeklyRepeaters';
import WeeklyRepeatersCVR from './charts/WeeklyRepeatersCVR';
import CustomerCurries from './charts/CustomerCurries';
import CumulativeOrders from './charts/CumulativeOrders';
import PaymentMethodOrders from './charts/PaymentMethodOrders';
import PlatformOrders from './charts/PlatformOrders';
import NthOrders from './charts/NthOrders';
import RavedCustomers from './charts/RavedCustomers';
import RavedCustomesrsFirstMonth from './charts/RavedCustomesrsFirstMonth';
import ChannelOrdersPerKitchenShop from './charts/ChannelOrdersPerKitchenShop';
import RepeaterOrdersPerKitchenShop from './charts/RepeaterOrdersPerKitchenShop';
import Stars from './charts/Stars';

export default class ChartConfig {
  static all() {
    return [
      {
        path: '/orders',
        title: 'カレー数推移',
        component: Orders,
        options: {},
      },
      {
        path: '/repeater_orders',
        title: 'カレー数推移(新規・リピーター別)',
        component: RepeaterOrders,
        options: {},
      },
      {
        path: '/channel_orders',
        title: 'カレー数推移(販売チャネル別)',
        component: ChannelOrders,
        options: {},
      },
      {
        path: '/channel_orders_detail',
        title: 'カレー数推移(販売チャネル詳細)',
        component: ChannelOrdersDetail,
        options: {},
      },
      {
        path: '/platform_orders',
        title: 'カレー数推移(プラットフォーム別)',
        component: PlatformOrders,
        options: {},
      },
      {
        path: '/payment_method_orders',
        title: 'カレー数推移(支払い方法別)',
        component: PaymentMethodOrders,
        options: {
          excludeZeroYen: true,
        },
      },
      {
        path: '/nth_orders',
        title: 'カレー数推移(注文回数別)',
        component: NthOrders,
        options: {},
      },
      {
        path: '/sales',
        title: '売り上げ/単価推移',
        component: Sales,
        options: {},
      },
      {
        path: '/customer_curries',
        title: 'カスタマー/カレー数',
        component: CustomerCurries,
        options: {},
      },
      {
        path: '/stars',
        title: 'スター',
        component: Stars,
        options: {},
      },
    ];
  }

  static all2() {
    return [
      {
        path: '/cumulative_orders',
        title: '累計カレー/カスタマー数',
        component: CumulativeOrders,
        options: {},
      },
      {
        path: '/raved',
        title: '5回目以上カスタマー',
        component: RavedCustomers,
        options: {},
      },
      {
        path: '/raved_first_month',
        title: '5回目以上カスタマー(初回月別)',
        component: RavedCustomesrsFirstMonth,
        options: {},
      },
      {
        path: '/repeaters',
        title: 'リピーター推移',
        component: Repeaters,
        options: {},
      },
      {
        path: '/30days_repeaters',
        title: '30日リピーター数増減',
        component: WeeklyRepeaters,
        options: {},
      },
      {
        path: '/repeaters_cvr',
        title: '新規-リピーターCVR',
        component: WeeklyRepeatersCVR,
        options: {},
      },
      {
        path: '/channel_orders_per_kitchen_shop',
        title: '親店舗平均カレー数推移(販売チャネル別)',
        component: ChannelOrdersPerKitchenShop,
        options: {},
      },
      {
        path: '/repeater_per_kitchen_shops',
        title: '親店舗平均カレー数推移(新規・リピーター別)',
        component: RepeaterOrdersPerKitchenShop,
        options: {},
      },
      {
        path: '/repeater_sales',
        title: '売り上げ/単価推移(初回除く)',
        component: Sales,
        options: {
          repeaterOnly: true,
        },
      },
    ];
  }
}
